import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faFilter,
  faEye,
  faEdit,
  faEyeSlash,
  faObjectGroup,
  faCaretDown,
  faTimes,
  faCheck,
  faTrashAlt,
  faSync,
  faShieldAlt,
  faQrcode,
  faPaperPlane,
  faLock,
  faLockOpen,
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faCopy,
  faLayerGroup,
  faUpload,
  faExclamationTriangle,
  faThLarge,
  faFile,
  faInfoCircle,
  faChevronDown,
  faChevronUp,
  faGlobe,
  faAdjust,
  faMinusCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
//import {far} from '@fortawesome/free-regular-svg-icons';  // Not free

library.add(
  faFilter,
  faEye,
  faEyeSlash,
  faEdit,
  faObjectGroup,
  faCaretDown,
  faTimes,
  faCheck,
  faTrashAlt,
  faSync,
  faShieldAlt,
  faQrcode,
  faPaperPlane,
  faLock,
  faLockOpen,
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faCopy,
  faLayerGroup,
  faUpload,
  faExclamationTriangle,
  faThLarge,
  faFile,
  faInfoCircle,
  faChevronDown,
  faChevronUp,
  faGlobe,
  faAdjust,
  faMinusCircle,
  faQuestionCircle
);
