import React, {useEffect} from 'react';
import {useLocation, BrowserRouter as Router} from 'react-router-dom';
import {ROUTER_SCROLL_TYPES} from 'configs';
import './styles/main.scss';
import routes from 'routes';
import renderRoutes from 'utils/render-routes';

// Contexts
import {AuthProvider} from 'contexts/AuthContext';
import {ACLProvider} from 'contexts/ACLContext';
import {AlertProvider} from 'contexts/AlertContext';
import {ViewportProvider} from 'contexts/ViewportContext';
import {LayoutProvider} from 'contexts/LayoutContext';
import {Page} from 'components/Pages';
import './AppIcons';

function ScrollToTop() {
  const {
    pathname,
    search,
    //hash,
    state,
  } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (!state || state.scroll !== ROUTER_SCROLL_TYPES.stay) {
        window.scrollTo(0, 0);
      }
    }, 10);
  }, [pathname, search, state]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <AuthProvider>
          <ACLProvider>
            <ViewportProvider>
              <LayoutProvider>
                <AlertProvider>
                  <Page>{renderRoutes(routes)}</Page>
                </AlertProvider>
              </LayoutProvider>
            </ViewportProvider>
          </ACLProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
