export const UKAS_TITLE = 'UKAS CertCheck';
export const APP_STAGE = process.env.REACT_APP_STAGE;
export const UKAS_SUPPORT_EMAIL = 'support@certcheck.ukas.com';

export const IS_STAGE_PROD = APP_STAGE === 'production';
export const IS_ENV_DEV = process.env.NODE_ENV === 'development';

export const API_URL = process.env.REACT_APP_API_URL;

export const UKAS_FRONTEND_URL = process.env.REACT_APP_UKAS_FRONTEND_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const CDN_FILE_URL = process.env.REACT_APP_CDN_FILE_URL;

// Cookies Settings
export const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN;
export const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;
export const COOKIE_KEY = process.env.REACT_APP_SECRET_KEY;

export const DATA_VERSION = process.env.REACT_APP_DATA_VERSION;
export const PASSWORD_EXPIRY_DAYS = 90;
