import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const schemesLibrary = {
  path: ROUTE_PATH.DASHBOARD.SCHEMES.INDEX,
  component: Loadable(() => import('../views/schemes-library')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SCHEMES.LIST,
      component: Loadable(() => import('../views/schemes-library/list')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.SCHEMES.OWNERS.INDEX,
      component: Loadable(() => import('../views/schemes-library/owners')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.SCHEMES.OWNERS.LIST,
          component: Loadable(() =>
            import('../views/schemes-library/owners/list')
          ),
        },
        {
          path: ROUTE_PATH.DASHBOARD.SCHEMES.OWNERS.VIEWS.INDEX,
          component: Loadable(() =>
            import('../views/schemes-library/owners/views')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.SCHEMES.OWNERS.VIEWS.VIEW,
              component: Loadable(() =>
                import('../views/schemes-library/owners/views/view')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.SCHEMES.OWNERS.VIEWS.SCHEMES,
              component: Loadable(() =>
                import('../views/schemes-library/owners/views/schemes')
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default schemesLibrary;
