import {Redirect} from 'react-router-dom';
import {ROUTE_ACCESS_RULES} from 'configs';
import Loadable from 'components/Loadable';

import home from './home';
import dashboard from './dashboard';
import certificationBodies from './certification-bodies';
import certifications from './certifications';
import certifiedEntities from './certified-entities';
import companies from './companies';
import adminUsers from './admin-users';
import apps from './apps';
import enquiries from './enquiries';
import libraries from './libraries';

import schemesLibrary from './schemes-library';
import sectorsLibrary from './sectors-library';
import settings from './settings';
import password from './password';
// import standards from './standards';
import standardsLibrary from './standards-library';
import sectors from './sectors';
import users from './users';
import securityFirewall from './security-firewall';

import dataSync from './data-sync';

const routes = [
  {...home, access: ROUTE_ACCESS_RULES.public},
  {...password},
  {...dashboard, access: ROUTE_ACCESS_RULES.private},
  {...certificationBodies, access: ROUTE_ACCESS_RULES.private},
  {...certifications, access: ROUTE_ACCESS_RULES.private},
  {...certifiedEntities, access: ROUTE_ACCESS_RULES.private},
  {...companies, access: ROUTE_ACCESS_RULES.private},

  {...sectors, access: ROUTE_ACCESS_RULES.private},
  {...enquiries, access: ROUTE_ACCESS_RULES.private},

  {...libraries, access: ROUTE_ACCESS_RULES.private},
  {...sectorsLibrary, access: ROUTE_ACCESS_RULES.private},
  {...schemesLibrary, access: ROUTE_ACCESS_RULES.private},
  {...standardsLibrary, access: ROUTE_ACCESS_RULES.private},

  {...securityFirewall, access: ROUTE_ACCESS_RULES.private},
  {...settings, access: ROUTE_ACCESS_RULES.private},
  {...apps, access: ROUTE_ACCESS_RULES.private},
  {...users, access: ROUTE_ACCESS_RULES.private},
  {...adminUsers, access: ROUTE_ACCESS_RULES.private},
  // {...standards, access: ROUTE_ACCESS_RULES.private},

  {...dataSync, access: ROUTE_ACCESS_RULES.private},

  {
    exact: true,
    path: '/error/:code',
    component: Loadable(() => import('views/errors')),
  },
  {
    exact: true,
    path: '/not-found',
    component: Loadable(() => import('../views/errors')),
  },
  {
    component: () => <Redirect to="/not-found" />,
  },
];

export default routes;
