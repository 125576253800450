import {isObject, isString, isEmpty} from 'lodash/lang';
import {IMAGE_WIDTHS} from 'configs';
import {CDN_URL} from 'configs/environment';
import urlJoin from 'url-join';

export const fileToImageUrl = (file, size = IMAGE_WIDTHS.origin) => {
  if (!isObject(file) && isString(file)) {
    try {
      file = JSON.parse(file);
    } catch (e) {
      file = {};
    }
  }

  // IMPORTANT: check after parse.
  if (isEmpty(file)) return null;

  const {files_disk_name, files_disk_path, files_thumbnail_name} = file;

  let url;

  if (IMAGE_WIDTHS[size]) {
    if (size === IMAGE_WIDTHS.origin) {
      url = urlJoin(CDN_URL, files_disk_path, files_disk_name);
      // `${CDN_URL}${files_disk_path}/${files_disk_name}`
    } else {
      url = urlJoin(
        CDN_URL,
        files_disk_path,
        files_thumbnail_name[IMAGE_WIDTHS[size]]
      );
      /*url = `${CDN_URL}${files_disk_path}/${
        files_thumbnail_name[IMAGE_WIDTHS[size]]
      }`;*/
    }
  }

  return url;
};

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], {type: mimeString});
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function returnFileSize(number) {
  // return file size of MB
  return (number / 1048576).toFixed(1);
}

export function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

const fileTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg',
  'image/svg+xml',
  'image/gif',
];

export function validFileType(file) {
  return fileTypes.includes(file.type);
}
