// import React from 'react';
import loadable from '@loadable/component';
// import pMinDelay from 'p-min-delay';
// import {PageLoading} from 'components/Loadings';

/*function Loadable(component) {
  return loadable(() => pMinDelay(component, 200), {
    fallback: <PageLoading position="absolute" isLoading />,
  });
}*/

const Loadable = (component) => {
  return loadable(component);
};

export default Loadable;
