import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {ROUTE_PATH} from 'configs/routes';
import {ROUTE_ACCESS_RULES} from 'configs';
import {useAuth} from 'contexts/AuthContext';

const RouteComponent = (props) => {
  const {access, routes, component: Component, ...rest} = props;
  const {isAuth} = useAuth();

  // Redirect to home if not authenticated
  if (access === ROUTE_ACCESS_RULES.private) {
    if (!isAuth) {
      return <Redirect to={ROUTE_PATH.HOME} />;
    }
  } else if (access === ROUTE_ACCESS_RULES.public) {
    // redirect to dashboard if authenticated and trying to access page of access type of public
    if (isAuth) {
      return <Redirect to={ROUTE_PATH.DASHBOARD.INDEX} />;
    }
  } else if (access === ROUTE_ACCESS_RULES.guest && isAuth) {
    return <Redirect to={ROUTE_PATH.DASHBOARD.INDEX} />;
  }

  return <Component routes={routes} {...rest} />;
};

function renderRoutes(routes) {
  return (
    <Switch>
      {routes.map((route, index) => {
        const {access, routes, component, path, ...rest} = route;

        return (
          <Route
            key={index}
            path={path}
            {...rest}
            render={(routeProps) => {
              return (
                <RouteComponent
                  access={access}
                  routes={routes}
                  component={component}
                  {...routeProps}
                />
              );
            }}
          />
        );
      })}

      <Route exact path="*" render={() => <Redirect to="/error/404" />} />
    </Switch>
  );
}

export default renderRoutes;
