import React, {createContext, useState, useContext} from 'react';
import Cookies from 'utils/cookies';
import {isEmpty} from 'lodash';
import {login, loginOTP, verifyEmailGuard} from 'api/auth';

export const AuthContext = createContext({});

// Provider
function AuthProvider(props) {
  const {permission, children} = props;
  const [session, setSession] = useState(Cookies.session || null);
  const [isAuth, setIsAuth] = useState(!isEmpty(session));
  const [account, setAccount] = useState(
    !isEmpty(session) ? session.account : {}
  );
  const [token, setToken] = useState(!isEmpty(session) ? session.token : '');

  async function _login(formData, callback) {
    const {data} = await login(formData);

    if (!data.session_id) {
      callback(data);
    } else {
      const session = {
        account: data.account,
        token: data.session_id,
      };

      Cookies.session = session;

      setSession(session);
      setAccount(session.account);
      setToken(session.token);
      setIsAuth(true);
    }
  }

  async function _loginEmail(formData) {
    const {data} = await verifyEmailGuard(formData);

    const session = {
      account: data.account,
      token: data.session_id,
    };

    Cookies.session = session;

    setSession(session);
    setIsAuth(true);
    setAccount(session.account);
    setToken(session.token);
  }

  async function _loginOTP(formData) {
    const {data} = await loginOTP(formData);

    const session = {
      account: data.account,
      token: data.session_id,
    };

    Cookies.session = session;

    setSession(session);
    setIsAuth(true);
    setAccount(session.account);
    setToken(session.token);
  }

  async function _logout() {
    Cookies.session = '';

    setSession(null);
    setIsAuth(false);
    setAccount({});
    setToken('');
  }

  return (
    <AuthContext.Provider
      value={{
        permission,
        session,
        isAuth,
        account,
        token,
        onLogin: _login,
        onLoginEmail: _loginEmail,
        onLoginOTP: _loginOTP,
        onLogout: _logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Consumer
const AuthConsumer = AuthContext.Consumer;

// Hook
const useAuth = () => useContext(AuthContext);

export function withAuth(Component) {
  return function (props) {
    const auth = useContext(AuthContext);

    return <Component auth={auth} {...props} />;
  };
}

export {AuthProvider, AuthConsumer, useAuth};
