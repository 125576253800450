import {buildRouteNodes} from 'utils/routes';

const ENQUIRIES = {
  INDEX: '/enquiries',

  LIST: '/',
  VIEWS: {
    INDEX: '/:enquiryId',
    VIEW: '/',
  },
};

const USERS = {
  INDEX: '/users',

  LIST: '/',
  CREATE: '/create',
  EDIT: '/:userId/edit',

  VIEWS: {
    INDEX: '/:userId',

    EDIT: '/edit',

    VIEW: '/',
    SECURITY_SETTINGS: '/security-settings',
    EMAIL_LOGS: '/email-logs',
  },
};

// Uncomment this if you want to implement import settings routing
const DATA_MANAGEMENT = {
  INDEX: '/data-management',

  FILE_UPLOAD: '/file-upload',
  FTP_ACCESS: '/ftp-access',
  API_ACCESS: '/api-access',

  IMPORT_SETTINGS: {
    INDEX: '/import-settings',

    DATA_MAPPING: '/mapping',
    FIELD_MAPPING: '/field',
    CE_NAME_MAPPING: {
      INDEX: '/ce-name-mapping',
      LIST: '/',
      SETTINGS: '/settings',
    },
    DATE_FORMAT_MAPPING: '/date-format-mapping',
    CIN: 'cin',
  },
  ACTIVITY: '/activity',
};

const BRANCHES = {
  INDEX: '/branches',
  LIST: '/',
  CREATE: '/create',

  VIEWS: {
    INDEX: '/:branchId',

    VIEW: '/',
    EDIT: '/edit',
    USERS: '/users',
  },
};

const CERTS = {
  INDEX: '/certifications',

  LIST: '/',
  CREATE: '/create',
  IMPORT_HISTORY: '/import-history',

  VIEWS: {
    INDEX: '/:certId',

    VIEW: '/',
    EDIT: '/edit',

    SITES: '/sites',
    RENEWAL_HISTORY: '/renewal-history',
    UPDATE_HISTORY: '/update-history',
    TECHNICAL_SECTORS: '/technical-sectors',
  },
};

const CES = {
  INDEX: '/certified-entities',

  LIST: '/',
  CREATE: '/create',
  MERGE_HISTORY: '/merge-history',
  MERGE_CE: '/merge-ce',

  MULTI_CB: {
    INDEX: '/multi-cb',
    LIST: '/',

    VIEWS: {
      INDEX: '/:multiCBId',
      VIEW: '/',
      EDIT: '/edit',
    },
  },

  VIEWS: {
    INDEX: '/:ceId',
    VIEW: '/',
    EDIT: '/edit',
    ACTIVITY: '/activity',
    ENQUIRIES,
    USERS,
    CERTS,
  },
};

const CBS = {
  INDEX: '/certification-bodies',

  LIST: '/',
  CREATE: '/create',
  MERGE_HISTORY: '/merge-history',
  MERGE_CB: '/merge-cb',
  DATA_MANAGEMENT,
  MANUAL_PARTICIPATION_CONFIG: '/participation-config',

  VIEWS: {
    INDEX: '/:cbId',

    VIEW: '/',
    EDIT: '/edit',
    STANDARDS: '/standards',
    SECTORS: '/sectors',
    OFFICE_DIRECTORIES: '/office-directories',
    ENQUIRIES,
    CES,
    CERTS,
    DATA_MANAGEMENT,
    BRANCHES,
    USERS,
  },
};

const COMPANIES = {
  INDEX: '/companies',

  LIST: '/',
  CREATE: '/create',

  VIEWS: {
    INDEX: '/:companyId',

    VIEW: '/',
    EDIT: '/edit',
    ENQUIRIES,
    USERS,
  },
};

const ADMIN_USERS = {
  INDEX: '/admin-users',

  LIST: '/',
  CREATE: '/create',
  VIEWS: {
    INDEX: '/:adminId',

    VIEW: '/',
    EDIT: '/edit',
  },
  ROLES: {
    INDEX: '/roles',

    LIST: '/',
    CREATE: '/create',
    VIEWS: {
      INDEX: '/:roleId',
      VIEW: '/',
      EDIT: '/edit',
    },
  },
};

const LIBRARIES = {
  INDEX: '/libraries',
  LIST: '/',

  VIEWS: {
    INDEX: '/:standardId',
    VIEW: '/',
    CERTIFICATES: '/certificates',
  },
};

const SCHEMES = {
  INDEX: '/schemes',
  LIST: '/',

  OWNERS: {
    INDEX: '/owners',
    LIST: '/',

    VIEWS: {
      INDEX: '/:ownerId', // scheme owner Id
      VIEW: '/', //scheme owner details
      SCHEMES: '/assigned-schemes', // owners schemes
    },
  },
};

const SECTORS = {
  INDEX: '/sectors',
  LIST: '/',
  GROUP: {
    INDEX: '/sector-group',
    LIST: '/',
  },
};

const DATA_SYNC = {
  INDEX: '/data-sync',
  LIST: '/',
};

const DASHBOARD = {
  INDEX: '/dashboard',
  OVERVIEW: '/dashboard',

  CERTS: buildRouteNodes(CERTS),
  CES: buildRouteNodes(CES),
  CBS: buildRouteNodes(CBS),
  COMPANIES: buildRouteNodes(COMPANIES),
  USERS: buildRouteNodes(USERS),
  ADMIN_USERS: buildRouteNodes(ADMIN_USERS),
  ENQUIRIES: buildRouteNodes(ENQUIRIES),
  LIBRARIES: buildRouteNodes(LIBRARIES),

  UKAS_SECTORS: '/ukas-sectors',
  // SECTORS: '/sectors', // Global Sectors
  SECTORS: buildRouteNodes(SECTORS),
  SCHEMES: buildRouteNodes(SCHEMES), // Schemes
  STANDARDS: '/standards', // Standard codes

  DATA_SYNC: buildRouteNodes(DATA_SYNC),

  APPS: '/apps',
  SETTINGS: '/settings',
  SECURITY_FIREWALL: '/security-firewall',
};

export default DASHBOARD;
