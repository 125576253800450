import http from 'utils/http';

export const login = (data) => {
  return http('POST', 'auth/admin/login', data);
};

export const forgotPassword = (data) => {
  return http('POST', '/auth/admin/forgot', data);
};

export const resetPassword = (data) => {
  return http('POST', '/auth/admin/reset', data);
};

export const sendEmailGuard = (data) => {
  return http('POST', '/auth/admin/email-guard/send', data);
};

export const verifyEmailGuard = (data) => {
  return http('POST', '/auth/admin/email-guard/verify', data);
};

export const loginOTP = (data) => {
  return http('POST', '/auth/admin/authenticator/verify', data);
};

/**
 * Check Password Vulnerability
 * @param data: password
 * @returns password_breached: true || false
 */
export const postCheckPassword = (data) => {
  return http('POST', '/auth/password/check', data);
};
