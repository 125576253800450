import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useCallback,
} from 'react';
import cx from 'classnames';
import {merge} from 'lodash/object';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import {Icon} from 'components/Icons';

const AlertContext = createContext({});

const initialState = {
  icon: false,
  iconType: '',
  isOpen: false,
  title: null,
  text: null,
  okButton: true,
  okText: 'OK',
  okColor: 'primary',
  cancelButton: false,
  cancelText: 'Cancel',
  cancelColor: 'white',
  action: null,
  isSubmitting: false,
};

function AlertProvider(props) {
  const {children} = props;
  const [modal, setModal] = useState(initialState);
  const refModal = useRef({});
  const show = useCallback((options) => {
    return new Promise((resolve, reject) => {
      refModal.current = {
        resolve,
        reject,
      };

      // Merge then set Modal options and states
      setModal((prevState) => {
        return merge({}, prevState, options, {isOpen: true});
      });
    });
  }, []);

  function handleCancel(e) {
    if (e) e.preventDefault();
    const {resolve} = refModal.current;
    resolve(false);
    // Close Modal

    setModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
      };
    });
  }

  async function handleOk(e) {
    if (e) e.preventDefault();
    if (modal.action) {
      setModal((prevState) => {
        return {
          ...prevState,
          isSubmitting: true,
        };
      });
      await modal.action();

      setModal((prevState) => {
        return {
          ...prevState,
          isSubmitting: false,
        };
      });
    }
    const {resolve} = refModal.current;
    resolve(true);

    // Close Modal
    setModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
      };
    });
  }
  const {
    isOpen,
    icon,
    iconType,
    title,
    text,
    okButton,
    okText,
    okColor,
    cancelButton,
    cancelText,
    cancelColor,
  } = modal;

  function handleToggle() {
    handleCancel();
  }

  function handleClosed() {
    setModal(initialState); // Reset Modal
  }

  return (
    <AlertContext.Provider value={{show}}>
      {children}
      <Modal
        isOpen={isOpen}
        backdrop="static"
        keyboard={false}
        toggle={handleToggle} // callback for toggling isOpen in the controlling component
        onClosed={handleClosed} // called when done transitioning out
        centered
      >
        {title && (
          <ModalHeader toggle={handleToggle}>
            {icon && (
              <Icon
                type="fa"
                append={title}
                icon={icon}
                className={cx({
                  [`text-${iconType}`]: iconType,
                })}
              />
            )}
          </ModalHeader>
        )}
        <ModalBody>{text}</ModalBody>
        <ModalFooter>
          {cancelButton && (
            <Button
              color={cancelColor}
              style={{minWidth: 80}}
              onClick={handleCancel}
            >
              {cancelText}
            </Button>
          )}
          {okButton && (
            <Button color={okColor} style={{minWidth: 80}} onClick={handleOk}>
              {modal.isSubmitting ? <Spinner size="sm" /> : okText}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </AlertContext.Provider>
  );
}

const AlertConsumer = AlertContext.Consumer;

const useAlert = () => useContext(AlertContext);
export {AlertProvider, AlertConsumer, useAlert};
