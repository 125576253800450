import React from 'react';
import PageContainer from './PageContainer';
import {useLayout} from 'contexts/LayoutContext';
import PageHeader from './PageHeader';
import PageAside from './PageSidebar';

function Page(props) {
  const {children} = props;
  const {header, aside} = useLayout();

  return (
    <PageContainer>
      {header && <PageHeader />}
      {aside && <PageAside />}
      <main
        className="main d-flex flex-column"
        style={{marginLeft: aside && '16.25rem'}} // width of sidebar
      >
        {children}
      </main>
    </PageContainer>
  );
}

export default Page;
