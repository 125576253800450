import {UKAS_FRONTEND_URL} from './environment';
import urlJoin from 'url-join';

export const ROUTER_SCROLL_TYPES = {
  stay: 'stay',
};

export const ROUTE_ACCESS_RULES = {
  public: 'public',
  private: 'private',
  guest: 'guest',
};

export const SIZES = {
  origin: 'origin',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const VIEWPORT_WIDTHS = {
  [SIZES.xs]: 0,
  [SIZES.sm]: 576,
  [SIZES.md]: 768,
  [SIZES.lg]: 992,
  [SIZES.xl]: 1200,
};

export const IMAGE_WIDTHS = {
  [SIZES.sm]: 160,
  [SIZES.md]: 320,
  [SIZES.lg]: 640,
  [SIZES.origin]: SIZES.origin,
};

export const ERROR_STATUS = {
  400: '400', // 400 Bad Request
  401: '401', //  401 Unauthorized
  403: '403', //  403 Forbidden, The client does not have access rights
  404: '404', //  404 Not found
  500: '500', //  500 Internal Server Error
  503: '503',
  session: 'session', //  session expired
  504: '504',
};

export const CB_PUBLIC_PROFILE_URL = (cbId) =>
  urlJoin(UKAS_FRONTEND_URL, 'certification-body', cbId);
export const CE_PUBLIC_PROFILE_URL = (ceId) =>
  urlJoin(UKAS_FRONTEND_URL, 'certified-entity', ceId);
export const CB_ACTIVATION_URL = (cbId) =>
  urlJoin(UKAS_FRONTEND_URL, 'act', cbId);
export const COMPANY_ACTIVATE_URL = (companyId) =>
  urlJoin(UKAS_FRONTEND_URL, 'act', companyId);

export const AB_ID = 'c9a9c89b-e3dc-53fb-8a06-1b5b32b72e53';

export const SECURITY_TYPE = {
  sign_in: 'Log in',
  sign_out: 'Log out',
  sign_up: 'Sign up',
  activation: 'Activate account',
  change_password: 'Change password',
  update_profile: 'Update profile',
  add_office_directory: 'Add office directory',
  update_office_directory: 'Update office directory',
  update_user_settings: 'Update user settings',
  delete_account: 'Delete account',
  update_security_settings: 'Update security setting',
  update_notification_settings: 'Update notification setting',
  delete_certification: 'Delete certification',
  delete_all_certifications: 'Delete all certifications',
  delete_all_ce_certifications: 'Delete all certifications under',
};
