import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const adminUsers = {
  path: ROUTE_PATH.DASHBOARD.ADMIN_USERS.INDEX,
  component: Loadable(() => import('../views/admin-users')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.ADMIN_USERS.LIST,
      component: Loadable(() => import('../views/admin-users/list')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.ADMIN_USERS.CREATE,
      component: Loadable(() => import('../views/admin-users/create')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.ADMIN_USERS.VIEWS.EDIT,
      component: Loadable(() => import('../views/admin-users/edit')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.ADMIN_USERS.ROLES.INDEX,
      component: Loadable(() => import('../views/admin-users/roles')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.ADMIN_USERS.ROLES.LIST,
          component: Loadable(() => import('../views/admin-users/roles/list')),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.ADMIN_USERS.ROLES.CREATE,
          component: Loadable(() =>
            import('../views/admin-users/roles/create')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.ADMIN_USERS.ROLES.VIEWS.EDIT,
          component: Loadable(() => import('../views/admin-users/roles/edit')),
        },
      ],
    },
  ],
};

export default adminUsers;
