import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const certificationBodies = {
  path: ROUTE_PATH.DASHBOARD.CBS.INDEX,
  component: Loadable(() => import('../views/certification-bodies')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CBS.LIST,
      component: Loadable(() => import('../views/certification-bodies/list')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CBS.CREATE,
      component: Loadable(() => import('../views/certification-bodies/create')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CBS.MERGE_HISTORY,
      component: Loadable(() =>
        import('../views/certification-bodies/merge-history')
      ),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CBS.MERGE_CB,
      component: Loadable(() =>
        import('../views/certification-bodies/merge-cb')
      ),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CBS.MANUAL_PARTICIPATION_CONFIG,
      component: Loadable(() =>
        import('../views/certification-bodies/manual-participation')
      ),
    },
    {
      path: ROUTE_PATH.DASHBOARD.CBS.DATA_MANAGEMENT.INDEX,
      component: Loadable(() =>
        import('../views/certification-bodies/data-management')
      ),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.DATA_MANAGEMENT.INDEX,
          component: Loadable(() =>
            import('../views/certification-bodies/data-management/file-upload')
          ),
        },
        {
          // exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.DATA_MANAGEMENT.FTP_ACCESS,
          component: Loadable(() =>
            import('../views/certification-bodies/data-management/ftp-access')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.DATA_MANAGEMENT.API_ACCESS,
          component: Loadable(() =>
            import('../views/certification-bodies/data-management/api-access')
          ),
        },

        // Uncomment this if you want to implement import settings routing
        {
          path: ROUTE_PATH.DASHBOARD.CBS.DATA_MANAGEMENT.IMPORT_SETTINGS.INDEX,
          component: Loadable(() =>
            import(
              '../views/certification-bodies/data-management/import-settings'
            )
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.DATA_MANAGEMENT.IMPORT_SETTINGS
                .INDEX,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/data-management/import-settings/data-mapping'
                )
              ),
            },

            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.DATA_MANAGEMENT.IMPORT_SETTINGS
                .FIELD_MAPPING,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/data-management/import-settings/field-mapping'
                )
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.DATA_MANAGEMENT.IMPORT_SETTINGS
                .DATE_FORMAT_MAPPING,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/data-management/import-settings/date-mapping'
                )
              ),
            },
          ],
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.DATA_MANAGEMENT.ACTIVITY,
          component: Loadable(() =>
            import('../views/certification-bodies/data-management/activity')
          ),
        },
      ],
    },
    // Views
    {
      path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.INDEX,
      component: Loadable(() => import('../views/certification-bodies/views')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.VIEW,
          component: Loadable(() =>
            import('../views/certification-bodies/views/view')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.EDIT,
          component: Loadable(() =>
            import('../views/certification-bodies/views/edit')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.STANDARDS,
          component: Loadable(() =>
            import('../views/certification-bodies/views/standards')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.SECTORS,
          component: Loadable(() =>
            import('../views/certification-bodies/views/sectors')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.OFFICE_DIRECTORIES,
          component: Loadable(() =>
            import('../views/certification-bodies/views/office-directories')
          ),
        },
        {
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.INDEX,
          component: Loadable(() =>
            import('../views/certification-bodies/views/certified-entities')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.LIST,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certified-entities/list'
                )
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.CREATE,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certified-entities/create'
                )
              ),
            },
            {
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.VIEWS.INDEX,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certified-entities/views'
                )
              ),
              routes: [
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.VIEWS.VIEW,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certified-entities/views/view'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.VIEWS.EDIT,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certified-entities/views/edit'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.VIEWS.CERTS.LIST,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certified-entities/views/certifications'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.VIEWS.ACTIVITY,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certified-entities/views/activity'
                    )
                  ),
                },
              ],
            },
          ],
        },
        {
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.INDEX,
          component: Loadable(() =>
            import('../views/certification-bodies/views/certifications')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.LIST,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certifications/list'
                )
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.CREATE,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certifications/create'
                )
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.IMPORT_HISTORY,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certifications/import-history'
                )
              ),
            },
            {
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.VIEWS.INDEX,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certifications/views'
                )
              ),
              routes: [
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.VIEWS.VIEW,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certifications/views/view'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.VIEWS.EDIT,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certifications/views/edit'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.VIEWS.SITES,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certifications/views/sites'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.VIEWS
                    .TECHNICAL_SECTORS,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certifications/views/technical-sectors'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.VIEWS
                    .RENEWAL_HISTORY,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certifications/views/renewal-history'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.VIEWS
                    .UPDATE_HISTORY,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/certifications/views/update-history'
                    )
                  ),
                },
              ],
            },
          ],
        },
        {
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT.INDEX,
          component: Loadable(() =>
            import('../views/certification-bodies/views/data-management')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT.INDEX,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/data-management/file-upload'
                )
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT.FTP_ACCESS,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/data-management/ftp-access'
                )
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT.API_ACCESS,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/data-management/api-access'
                )
              ),
            },
            {
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT
                .IMPORT_SETTINGS.INDEX,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/data-management/import-settings'
                )
              ),
              routes: [
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT
                    .IMPORT_SETTINGS.INDEX,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/data-management/import-settings/data-mapping'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT
                    .IMPORT_SETTINGS.FIELD_MAPPING,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/data-management/import-settings/field-mapping'
                    )
                  ),
                },
                {
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT
                    .IMPORT_SETTINGS.CE_NAME_MAPPING.INDEX,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/data-management/import-settings/ce-mapping'
                    )
                  ),
                  routes: [
                    {
                      exact: true,
                      path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT
                        .IMPORT_SETTINGS.CE_NAME_MAPPING.INDEX,
                      component: Loadable(() =>
                        import(
                          '../views/certification-bodies/views/data-management/import-settings/ce-mapping/list'
                        )
                      ),
                    },
                    {
                      exact: true,
                      path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT
                        .IMPORT_SETTINGS.CE_NAME_MAPPING.SETTINGS,
                      component: Loadable(() =>
                        import(
                          '../views/certification-bodies/views/data-management/import-settings/ce-mapping/settings'
                        )
                      ),
                    },
                  ],
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT
                    .IMPORT_SETTINGS.DATE_FORMAT_MAPPING,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/data-management/import-settings/date-mapping'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT
                    .IMPORT_SETTINGS.CIN,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/data-management/import-settings/cin'
                    )
                  ),
                },
              ],
            },

            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT
                .DATE_FORMAT_MAPPING,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/data-management/import-settings/date-mapping'
                )
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DATA_MANAGEMENT.ACTIVITY,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/data-management/activity'
                )
              ),
            },
          ],
        },
        {
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.BRANCHES.INDEX,
          component: Loadable(() =>
            import('../views/certification-bodies/views/branches')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.BRANCHES.LIST,
              component: Loadable(() =>
                import('../views/certification-bodies/views/branches/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.BRANCHES.CREATE,
              component: Loadable(() =>
                import('../views/certification-bodies/views/branches/create')
              ),
            },
            {
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.BRANCHES.VIEWS.INDEX,
              component: Loadable(() =>
                import('../views/certification-bodies/views/branches/views')
              ),
              routes: [
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.BRANCHES.VIEWS.VIEW,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/branches/views/view'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.BRANCHES.VIEWS.EDIT,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/branches/views/edit'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.BRANCHES.VIEWS.USERS,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/branches/views/users'
                    )
                  ),
                },
              ],
            },
          ],
        },
        {
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.ENQUIRIES.INDEX,
          component: Loadable(() =>
            import('../views/certification-bodies/views/enquiries')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.ENQUIRIES.LIST,
              component: Loadable(() =>
                import('../views/certification-bodies/views/enquiries/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.ENQUIRIES.VIEWS.VIEW,
              component: Loadable(() =>
                import('../views/certification-bodies/views/enquiries/view')
              ),
            },
          ],
        },
        {
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.USERS.INDEX,
          component: Loadable(() =>
            import('../views/certification-bodies/views/users')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.USERS.LIST,
              component: Loadable(() =>
                import('../views/certification-bodies/views/users/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.USERS.CREATE,
              component: Loadable(() =>
                import('../views/certification-bodies/views/users/create')
              ),
            },
            {
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.USERS.VIEWS.INDEX,
              component: Loadable(() =>
                import('../views/certification-bodies/views/users/views')
              ),
              routes: [
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.USERS.VIEWS.VIEW,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/users/views/view'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.USERS.VIEWS.EDIT,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/users/views/edit'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.USERS.VIEWS
                    .SECURITY_SETTINGS,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/users/views/security-settings'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.USERS.VIEWS.EMAIL_LOGS,
                  component: Loadable(() =>
                    import(
                      '../views/certification-bodies/views/users/views/email-logs'
                    )
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default certificationBodies;
