import React from 'react';
import {Navbar, NavItem, NavLink as BootNavLink} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {get, isEmpty} from 'lodash';
import {ROUTE_PATH} from 'configs/routes';
import {useACL} from 'contexts/ACLContext';

function hasPermit({pathPermit, path, text}) {
  const {permission} = useACL();

  if (get(permission, pathPermit)) {
    return {
      to: path,
      text: text,
    };
  }
}

const AsideSubTitle = ({title}) => (
  <li className="nav-item">
    <small className="nav-subtitle text-muted">{title}</small>
  </li>
);

const AsideNavItem = ({text, ...rest}) => {
  return (
    <NavItem>
      <BootNavLink tag={NavLink} {...rest}>
        <span className="ml-2 navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
          {text}
        </span>
      </BootNavLink>
    </NavItem>
  );
};

function PageSidebar() {
  const companyType = [
    hasPermit({
      pathPermit: 'certification_bodies.access',
      path: ROUTE_PATH.DASHBOARD.CBS.LIST,
      text: 'Certification Bodies',
    }),

    hasPermit({
      pathPermit: 'certified_entities.access',
      path: ROUTE_PATH.DASHBOARD.CES.LIST,
      text: 'Certified Entities',
    }),

    hasPermit({
      pathPermit: 'verification_users.access',
      path: ROUTE_PATH.DASHBOARD.COMPANIES.LIST,
      text: 'Verification Users',
    }),
  ].filter((v) => v);

  const certificates = [
    hasPermit({
      pathPermit: 'certifications.access',
      path: ROUTE_PATH.DASHBOARD.CERTS.LIST,
      text: 'Certifications',
    }),
  ].filter((v) => v);

  const libraries = [
    hasPermit({
      pathPermit: 'ukas_standards_schemes.access',
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.LIST,
      text: 'UKAS Standards and Schemes',
    }),

    hasPermit({
      pathPermit: 'ukas_sectors.access',
      path: ROUTE_PATH.DASHBOARD.UKAS_SECTORS,
      text: 'UKAS Sectors',
    }),

    hasPermit({
      pathPermit: 'sectors_library.access',
      path: ROUTE_PATH.DASHBOARD.SECTORS.LIST,
      text: 'Sectors Library',
    }),

    hasPermit({
      pathPermit: 'schemes_library.access',
      path: ROUTE_PATH.DASHBOARD.SCHEMES.LIST,
      text: 'Schemes Library',
    }),

    hasPermit({
      pathPermit: 'standards_library.access',
      path: ROUTE_PATH.DASHBOARD.STANDARDS,
      text: 'Standards Library',
    }),
  ].filter((v) => v);

  const messaging = [
    hasPermit({
      pathPermit: 'enquiries.access',
      path: ROUTE_PATH.DASHBOARD.ENQUIRIES.LIST,
      text: 'Enquiries',
    }),
  ].filter((v) => v);

  const adminSettings = [
    hasPermit({
      pathPermit: 'security_firewall.access',
      path: ROUTE_PATH.DASHBOARD.SECURITY_FIREWALL,
      text: 'Security Firewall',
    }),

    hasPermit({
      pathPermit: 'apps.access',
      path: ROUTE_PATH.DASHBOARD.APPS,
      text: 'Apps',
    }),

    hasPermit({
      pathPermit: 'ukas_users.access',
      path: ROUTE_PATH.DASHBOARD.USERS.LIST,
      text: 'UKAS Users',
    }),

    hasPermit({
      pathPermit: 'admin_users.access',
      path: ROUTE_PATH.DASHBOARD.ADMIN_USERS.LIST,
      text: 'Admin Users',
    }),

    hasPermit({
      pathPermit: 'settings.access',
      path: ROUTE_PATH.DASHBOARD.SETTINGS,
      text: 'Settings',
    }),
  ].filter((v) => v);

  const dataSync = [
    {
      // temp, should change the permission in the account
      to: ROUTE_PATH.DASHBOARD.DATA_SYNC.LIST,
      text: 'Data Sync Management',
    },
  ].filter((v) => v);

  return (
    <Navbar
      tag="aside"
      className="navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered navbar-vertical-aside-initialized navbar light"
      style={{marginTop: '3.75rem'}} // height of navbar
    >
      <div className="navbar-vertical-container py-4">
        <div className="navbar-vertical-content">
          <ul className="navbar-nav navbar-nav-lg nav-tabs">
            <AsideNavItem text="Dashboard" to={ROUTE_PATH.DASHBOARD.OVERVIEW} />

            {!isEmpty(companyType) && (
              <>
                <AsideSubTitle title="Company Type" />

                {companyType.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}
              </>
            )}

            {!isEmpty(certificates) && (
              <>
                <AsideSubTitle title="Certifications" />

                {certificates.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}
              </>
            )}

            {
              <>
                <AsideSubTitle title="Data Sync" />

                {dataSync.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}
              </>
            }

            {!isEmpty(libraries) && (
              <>
                <AsideSubTitle title="Libraries" />

                {libraries.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}
              </>
            )}

            {!isEmpty(messaging) && (
              <>
                <AsideSubTitle title="Messaging" />

                {messaging.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}
              </>
            )}

            {!isEmpty(adminSettings) && (
              <>
                <AsideSubTitle title="Admin Settings" />
                {adminSettings.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
    </Navbar>
  );
}

export default PageSidebar;
