import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const enquiries = {
  path: ROUTE_PATH.DASHBOARD.ENQUIRIES.INDEX,
  component: Loadable(() => import('../views/enquiries')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.ENQUIRIES.LIST,
      component: Loadable(() => import('../views/enquiries/list')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.ENQUIRIES.VIEWS.VIEW,
      component: Loadable(() => import('../views/enquiries/view')),
    },
  ],
};

export default enquiries;
