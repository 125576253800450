import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const certifiedEntities = {
  path: ROUTE_PATH.DASHBOARD.CES.INDEX,
  component: Loadable(() => import('../views/certified-entities')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CES.LIST,
      component: Loadable(() => import('../views/certified-entities/list')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CES.CREATE,
      component: Loadable(() => import('../views/certified-entities/create')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CES.MERGE_HISTORY,
      component: Loadable(() =>
        import('../views/certified-entities/merge-history')
      ),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CES.MERGE_CE,
      component: Loadable(() => import('../views/certified-entities/merge-ce')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.CES.MULTI_CB.INDEX,
      component: Loadable(() => import('../views/certified-entities/multi-cb')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CES.MULTI_CB.LIST,
          component: Loadable(() =>
            import('../views/certified-entities/multi-cb/list')
          ),
        },
        {
          path: ROUTE_PATH.DASHBOARD.CES.MULTI_CB.VIEWS.INDEX,
          component: Loadable(() =>
            import('../views/certified-entities/multi-cb/views')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.MULTI_CB.VIEWS.VIEW,
              component: Loadable(() =>
                import('../views/certified-entities/multi-cb/views/view')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.MULTI_CB.VIEWS.EDIT,
              component: Loadable(() =>
                import('../views/certified-entities/multi-cb/views/edit')
              ),
            },
          ],
        },
        // {
        //   exact: true,
        //   path: ROUTE_PATH.DASHBOARD.CES.MULTI_CB.VIEWS.EDIT,
        //   component: Loadable(() =>
        //     import('../views/certified-entities/multi-cb/views/edit')
        //   ),
        // },
      ],
    },
    // Views
    {
      path: ROUTE_PATH.DASHBOARD.CES.VIEWS.INDEX,
      component: Loadable(() => import('../views/certified-entities/views')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.VIEW,
          component: Loadable(() =>
            import('../views/certified-entities/views/view')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.EDIT,
          component: Loadable(() =>
            import('../views/certified-entities/views/edit')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.ACTIVITY,
          component: Loadable(() =>
            import('../views/certified-entities/views/activity')
          ),
        },
        {
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.INDEX,
          component: Loadable(() =>
            import('../views/certified-entities/views/certifications')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.LIST,
              component: Loadable(() =>
                import('../views/certified-entities/views/certifications/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.CREATE,
              component: Loadable(() =>
                import(
                  '../views/certified-entities/views/certifications/create'
                )
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.IMPORT_HISTORY,
              component: Loadable(() =>
                import(
                  '../views/certified-entities/views/certifications/import-history'
                )
              ),
            },
            {
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.VIEWS.INDEX,
              component: Loadable(() =>
                import('../views/certified-entities/views/certifications/views')
              ),
              routes: [
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.VIEWS.VIEW,
                  component: Loadable(() =>
                    import(
                      '../views/certified-entities/views/certifications/views/view'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.VIEWS.EDIT,
                  component: Loadable(() =>
                    import(
                      '../views/certified-entities/views/certifications/views/edit'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.VIEWS.SITES,
                  component: Loadable(() =>
                    import(
                      '../views/certified-entities/views/certifications/views/sites'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.VIEWS
                    .TECHNICAL_SECTORS,
                  component: Loadable(() =>
                    import(
                      '../views/certified-entities/views/certifications/views/technical-sectors'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.VIEWS
                    .RENEWAL_HISTORY,
                  component: Loadable(() =>
                    import(
                      '../views/certified-entities/views/certifications/views/renewal-history'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.VIEWS
                    .UPDATE_HISTORY,
                  component: Loadable(() =>
                    import(
                      '../views/certified-entities/views/certifications/views/update-history'
                    )
                  ),
                },
              ],
            },
          ],
        },
        {
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.ENQUIRIES.INDEX,
          component: Loadable(() =>
            import('../views/certified-entities/views/enquiries')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.ENQUIRIES.LIST,
              component: Loadable(() =>
                import('../views/certified-entities/views/enquiries/list/index')
              ),
            },
            {
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.ENQUIRIES.VIEW,
              component: Loadable(() =>
                import('../views/certified-entities/views/enquiries/view')
              ),
            },
          ],
        },
        {
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.USERS.INDEX,
          component: Loadable(() =>
            import('../views/certified-entities/views/users')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.USERS.LIST,
              component: Loadable(() =>
                import('../views/certified-entities/views/users/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.USERS.CREATE,
              component: Loadable(() =>
                import('../views/certified-entities/views/users/create')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.USERS.EDIT,
              component: Loadable(() =>
                import('../views/certified-entities/views/users/edit')
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default certifiedEntities;
