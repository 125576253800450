import React from 'react';
import PropTypes from 'prop-types';

import {NavbarBrand} from 'reactstrap';
import {InternalLink} from 'components/Links';
import {ImageLogo} from 'components/Images';
import cx from 'classnames';

LinkLogo.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
  isCrown: PropTypes.bool,
};

LinkLogo.defaultProps = {
  color: 'dark',
  size: 'md',
};

function LinkLogo(props) {
  const {color, size, className} = props;

  return (
    <NavbarBrand
      tag={InternalLink}
      path="HOME"
      title="Go to Home :: UKAS"
      className={cx(
        'd-flex justify-content-center align-items-center',
        className
      )}
    >
      <ImageLogo size={size} color={color} />
      <span className="text-body ml-2 mt-1"> | Administration</span>
    </NavbarBrand>
  );
}

export default LinkLogo;
