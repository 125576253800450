import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const users = {
  path: ROUTE_PATH.DASHBOARD.USERS.INDEX,
  component: Loadable(() => import('../views/users')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.USERS.LIST,
      component: Loadable(() => import('../views/users/list')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.USERS.CREATE,
      component: Loadable(() => import('../views/users/create')),
    },
    // {
    //   path: ROUTE_PATH.DASHBOARD.USERS.EDIT,
    //   component: Loadable(() => import('../views/users/edit')),
    // },
    {
      path: ROUTE_PATH.DASHBOARD.USERS.VIEWS.INDEX,
      component: Loadable(() => import('../views/users/views')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.USERS.VIEWS.VIEW,
          component: Loadable(() => import('../views/users/views/view')),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.USERS.VIEWS.EDIT,
          component: Loadable(() => import('../views/users/views/edit')),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.USERS.VIEWS.SECURITY_SETTINGS,
          component: Loadable(() =>
            import('../views/users/views/security-settings')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.USERS.VIEWS.EMAIL_LOGS,
          component: Loadable(() => import('../views/users/views/email-logs')),
        },
      ],
    },
  ],
};

export default users;
