import http from 'utils/http';

export const getRoles = () => {
  return http('GET', `admin/roles`);
};

export const getAdminRoles = () => {
  return http('GET', `admin/roles`);
};

export const getRole = (roleId) => {
  return http('GET', `/admin/roles/${roleId}`);
};

export const addRole = (data) => {
  return http('POST', '/admin/roles', data);
};

export const updateRole = (roleId, data) => {
  return http('PUT', `/admin/roles/${roleId}`, data);
};

export const deleteAdminRoles = (roleId) => {
  return http('DELETE', `/admin/roles/${roleId}`);
};

export const getPermissions = () => {
  return http('GET', `/admin/permissions/template`);
};

export const getCurrentPermissions = () => {
  return http('GET', `/admin/permissions`);
};
