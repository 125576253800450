import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const companies = {
  path: ROUTE_PATH.DASHBOARD.COMPANIES.INDEX,
  component: Loadable(() => import('../views/companies')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.COMPANIES.LIST,
      component: Loadable(() => import('../views/companies/list')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.COMPANIES.CREATE,
      component: Loadable(() => import('../views/companies/create')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.INDEX,
      component: Loadable(() => import('../views/companies/views')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.VIEW,
          component: Loadable(() => import('../views/companies/views/view')),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.EDIT,
          component: Loadable(() => import('../views/companies/views/edit')),
        },
        {
          path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.ENQUIRIES.INDEX,
          component: Loadable(() =>
            import('../views/companies/views/enquiries')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.ENQUIRIES.LIST,
              component: Loadable(() =>
                import('../views/companies/views/enquiries/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.ENQUIRIES.VIEW,
              component: Loadable(() =>
                import('../views/companies/views/enquiries/view')
              ),
            },
          ],
        },
        {
          path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.USERS.INDEX,
          component: Loadable(() => import('../views/companies/views/users')),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.USERS.LIST,
              component: Loadable(() =>
                import('../views/companies/views/users/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.USERS.CREATE,
              component: Loadable(() =>
                import('../views/companies/views/users/create')
              ),
            },
            {
              path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.USERS.VIEWS.INDEX,
              component: Loadable(() =>
                import('../views/companies/views/users/views')
              ),
              routes: [
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.USERS.VIEWS.VIEW,
                  component: Loadable(() =>
                    import('../views/companies/views/users/views/view')
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.USERS.VIEWS.EDIT,
                  component: Loadable(() =>
                    import('../views/companies/views/users/views/edit')
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.USERS.VIEWS
                    .SECURITY_SETTINGS,
                  component: Loadable(() =>
                    import(
                      '../views/companies/views/users/views/security-settings'
                    )
                  ),
                },
                {
                  exact: true,
                  path: ROUTE_PATH.DASHBOARD.COMPANIES.VIEWS.USERS.VIEWS
                    .EMAIL_LOGS,
                  component: Loadable(() =>
                    import('../views/companies/views/users/views/email-logs')
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default companies;
