import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const sectorsLibrary = {
  path: ROUTE_PATH.DASHBOARD.SECTORS.INDEX,
  component: Loadable(() => import('../views/sectors-library')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SECTORS.LIST,
      component: Loadable(() => import('../views/sectors-library/list')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.SECTORS.GROUP.LIST,
      component: Loadable(() => import('../views/sectors-library/group')),
    },
  ],
};

export default sectorsLibrary;
