import React from 'react';
import styles from './PageContainer.module.scss';

function PageContainer(props) {
  const {children} = props;

  return <div className={styles['page-container']}>{children}</div>;
}

export default PageContainer;
