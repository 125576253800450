import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const home = {
  exact: true,
  path: ROUTE_PATH.HOME,
  component: Loadable(() => import('../views/home')),
};

export default home;
