import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

ImageFlag.propTypes = {
  code: PropTypes.string,
  tag: PropTypes.string,
  round: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']), // 12px, 18px, 24px, 32px
};

ImageFlag.defaultProps = {
  tag: 'i',
  round: true,
  size: 'md',
};

function ImageFlag(props) {
  const {tag, code, size, round, className, style} = props;

  const _className = cx(
    'fflag',
    {
      [`fflag-${code}`]: code,
      [`ff-${size}`]: size,
      'ff-round': round,
    },
    className
  );

  return React.createElement(tag, {
    className: _className,
    style: {
      verticalAlign: 'text-bottom',
      ...style,
    },
  });
}

export default ImageFlag;
