import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const libraries = {
  path: ROUTE_PATH.DASHBOARD.LIBRARIES.INDEX,
  component: Loadable(() => import('../views/libraries')),

  routes: [
    // List
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.LIST,
      component: Loadable(() => import('../views/libraries/list')),
    },
    // Views
    {
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.VIEWS.INDEX,
      component: Loadable(() => import('../views/libraries/views')),
      routes: [
        // View
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.LIBRARIES.VIEWS.VIEW,
          component: Loadable(() => import('../views/libraries/views/view')),
        },

        // Assigned certificates
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.LIBRARIES.VIEWS.CERTIFICATES,
          component: Loadable(() =>
            import('../views/libraries/views/certificates')
          ),
        },
      ],
    },
  ],
};

export default libraries;
