import DASHBOARD from './routes.dashboard';

const PASSWORD = {
  INDEX: '/password',
  FORGOT: '/password/forgot',
  RESET: '/password/reset/:token',
};

export const ROUTE_PATH = {
  HOME: '/',
  PASSWORD,
  DASHBOARD,
};
