import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import IconFA from './IconFA';
import TIOIcon from './TIOIcon';

Icon.propTypes = {
  type: PropTypes.oneOf(['tio', 'fa']).isRequired,
  append: PropTypes.any,
  prepend: PropTypes.any,
};

Icon.defaultProps = {
  append: null,
  prepend: null,
};

function Icon(props) {
  const {type, icon, className, append, prepend, ...rest} = props;
  let iconComponent;

  const _className = cx(
    {
      'ml-1': prepend,
      'mr-1': append,
    },
    className
  );

  const hasAdditional = append || prepend;

  if (type === 'tio') {
    iconComponent = <TIOIcon icon={icon} className={_className} {...rest} />;
  }

  if (type === 'fa') {
    iconComponent = (
      <IconFA
        fixedWidth={!!hasAdditional}
        name={icon}
        className={_className}
        {...rest}
      />
    );
  }

  // Text with Icon
  if (prepend) {
    return (
      <>
        {prepend}
        {iconComponent}
      </>
    );
  }

  // Icon with Text
  if (append) {
    return (
      <>
        {iconComponent}
        {append}
      </>
    );
  }

  return iconComponent;
}

export default Icon;
