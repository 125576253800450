import Cookie from 'universal-cookie';
import {encrypt, decrypt} from 'utils/encrypts';
import {isEmpty} from 'lodash/lang';
import {
  APP_STAGE,
  IS_ENV_DEV,
  COOKIE_KEY,
  COOKIE_PREFIX,
  COOKIE_DOMAIN,
} from 'configs/environment';

// IAF admin only needs sessions and bsid on headers and on their cookies
// No expiry value

// Create a Cookie
const cookie = new Cookie();

function createCookieName(name) {
  if (!name || typeof name !== 'string') return null;
  return [COOKIE_PREFIX, name].filter((v) => v).join('-');
}

function getCookie(name, options) {
  const cookieName = createCookieName(name);
  return cookie.get(cookieName, options);
}

function setCookie(name, value, options) {
  const cookieName = createCookieName(name);
  const cookieOptions = {
    path: '/',
    domain: IS_ENV_DEV ? '' : COOKIE_DOMAIN,
    secure: APP_STAGE === 'production',
    sameSite: APP_STAGE === 'production' ? 'none' : '',
    ...options,
  };

  if (isEmpty(value)) {
    cookie.remove(cookieName, cookieOptions);
  } else {
    cookie.set(cookieName, value, cookieOptions);
  }
}

function getEncryptCookie(name, options) {
  const cookieValue = getCookie(name, options);
  return isEmpty(cookieValue) ? null : decrypt(cookieValue, COOKIE_KEY);
}

function setEncryptCookie(name, value, options) {
  const cookieValue = isEmpty(value)
    ? null
    : encrypt(value, COOKIE_KEY).toString();
  setCookie(name, cookieValue, options);
}

const Cookies = {
  get session() {
    return getEncryptCookie('session');
  },

  set session(objValue) {
    setEncryptCookie('session', objValue);
  },

  get bsid() {
    return getEncryptCookie('bsid');
  },

  set bsid(value) {
    setEncryptCookie('bsid', value);
  },
};

export default Cookies;
