import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const certifications = {
  path: ROUTE_PATH.DASHBOARD.CERTS.INDEX,
  component: Loadable(() => import('../views/certifications')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CERTS.LIST,
      component: Loadable(() => import('../views/certifications/list')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CERTS.CREATE,
      component: Loadable(() => import('../views/certifications/create')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.INDEX,
      component: Loadable(() => import('../views/certifications/views')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.VIEW,
          component: Loadable(() =>
            import('../views/certifications/views/view')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.EDIT,
          component: Loadable(() =>
            import('../views/certifications/views/edit')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.SITES,
          component: Loadable(() =>
            import('../views/certifications/views/sites')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.TECHNICAL_SECTORS,
          component: Loadable(() =>
            import('../views/certifications/views/technical-sectors')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.RENEWAL_HISTORY,
          component: Loadable(() =>
            import('../views/certifications/views/renewal-history')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.UPDATE_HISTORY,
          component: Loadable(() =>
            import('../views/certifications/views/update-history')
          ),
        },
      ],
    },
  ],
};

export default certifications;
