import React from 'react';
import {Button, Navbar} from 'reactstrap';
import {LinkLogo} from 'components/Links';
import {useAuth} from 'contexts/AuthContext';

function PageHeader() {
  const {onLogout} = useAuth();

  return (
    <Navbar
      tag="header"
      expand="lg"
      className="navbar-height navbar-flush navbar-theme navbar-bordered navbar-fixed"
    >
      <div className="navbar-nav-wrap ">
        <div className="navbar-nav-wrap-content-left">
          <div className="navbar-brand-wrapper">
            <LinkLogo />
          </div>
        </div>
        <div className="navbar-nav-wrap-content-right pr-4">
          <Button
            color="white"
            outline
            className="text-primary font-weight-bold"
            onClick={() => onLogout()}
          >
            Logout
          </Button>
        </div>
      </div>
    </Navbar>
  );
}

export default PageHeader;
