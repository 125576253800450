import React, {createContext, useContext, useEffect, useState} from 'react';
import {useAuth} from './AuthContext';
import useAlert from 'hooks/useAlert';
import {PageLoading} from 'components/Loadings';
import {getCurrentPermissions} from 'api/roles-api';
import {get} from 'lodash';

const ACLContext = createContext({});

function ACLProvider(props) {
  const {alertByError} = useAlert();
  const {isAuth} = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [permission, setPermission] = useState({});

  useEffect(() => {
    if (isAuth) {
      getPermission();
    } else {
      setIsLoading(false);
      setPermission({});
    }
  }, [isAuth]);

  async function getPermission() {
    try {
      const {data} = await getCurrentPermissions();
      setIsLoading(false);
      setPermission(data);
    } catch (e) {
      alertByError(e);
    }
  }

  const {children} = props;

  function checkPermission(key) {
    return get(permission, key);
  }

  return (
    <ACLContext.Provider
      value={{isLoading, permission, checkPermission, getPermission}}
    >
      {isLoading ? <PageLoading isLoading /> : children}
    </ACLContext.Provider>
  );
}

function withACL(Component) {
  return (props) => {
    const states = useContext(ACLContext);

    return <Component {...states} {...props} />;
  };
}

const useACL = () => useContext(ACLContext);

export {ACLContext, ACLProvider, withACL, useACL};
