import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'configs/routes';

const dataSync = {
  path: ROUTE_PATH.DASHBOARD.DATA_SYNC.INDEX,
  component: Loadable(() => import('../views/data-sync')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.DATA_SYNC.LIST,
      component: Loadable(() => import('../views/data-sync/list')),
    },
  ],
};

export default dataSync;
