import {ROUTE_PATH} from 'configs/routes';
import {ROUTE_ACCESS_RULES} from 'configs';
import Loadable from 'components/Loadable';

const password = {
  accessRule: ROUTE_ACCESS_RULES.guest, // accessRule: Only using with the top index.

  path: ROUTE_PATH.PASSWORD.INDEX,
  component: Loadable(() => import('../views/password')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.PASSWORD.FORGOT,
      component: Loadable(() => import('../views/password/forgot')),
    },
    {
      exact: true,
      path: ROUTE_PATH.PASSWORD.RESET,
      component: Loadable(() => import('../views/password/reset')),
    },
  ],
};

export default password;
